import './App.css';
import CoverdashQuotes from '@coverdash/quotes';
import { useCallback } from 'react';
import queryString  from 'query-string'

function App() {
  const queryParams = queryString.parse(window.location.search);
  const contactid = queryParams.contactid;
  const email = queryParams.email;
  const firstName = queryParams.fn;
  const lastName = queryParams.ln;

  var appId = '';
  var bizId = '';
  var boundPolicy = false;

  var useFakeQuotes = process.env.REACT_APP_COVERDASH_FAKE_QUOTE === 'true' ? true : false;

  var prefilledProps = new Object();
  prefilledProps.userEmail = email;
  
  const appSubmittedCb = useCallback(async(val) => {
    appId = val;
    await fetch('https://api.linqqs.com/v1/Coverdash', {
      method: 'POST',
      body: JSON.stringify({
        CoverdashLedger: {
          LinqqsContactId: contactid,
          LinqqsEmailAddress: email,
          CoverdashApplicationId: appId
        }
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    }).then((response => response.json())).catch((err)=>{console.log(err.message);});
  },[]);

  
  const bizChangeCb = useCallback(async(val) => {
    bizId = val;
    await fetch('https://api.linqqs.com/v1/Coverdash', {
      method: 'POST',
      body: JSON.stringify({
        CoverdashLedger: {
          LinqqsContactId: contactid,
          LinqqsEmailAddress: email,
          CoverdashBusinessId: bizId
        }
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    }).then((response => response.json())).catch((err)=>{console.log(err.message);});
  },[]);

  const boundPolicyCb = useCallback(async(val) => {
    boundPolicy = val;
    await fetch('https://api.linqqs.com/v1/Coverdash', {
      method: 'POST',
      body: JSON.stringify({
        CoverdashLedger: {
          LinqqsContactId: contactid,
          LinqqsEmailAddress: email,
          CoverdashBoundPolicy: boundPolicy
        }
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    }).then((response => response.json())).catch((err)=>{console.log(err.message);});
  },[]);

  return (
    <CoverdashQuotes 
    allowScroll={true} 
    license={process.env.REACT_APP_COVERDASH_LICENSE}
    onApplicationSubmitted={appSubmittedCb}
    onChangeBusiness={bizChangeCb}
    onBoundPolicy={boundPolicyCb}
    reset={true}
    prefilled={prefilledProps}
    env={process.env.REACT_APP_COVERDASH_ENV}
    fakeQuote={useFakeQuotes}
    />    
  );
}

export default App;
